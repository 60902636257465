import React, { useState, useEffect, useContext } from "react";
import { SenseContext } from "../store/Sense";
import SenseSection from "../components/SenseList";
import RecommendSection from "../components/RecommendAreas";
import request from "../utils/request";
import apiMap from "../api";
import { WhiteSpace } from "antd-mobile";

import { getLocation } from "../utils/app";
import { setLocation } from "../action/app";

const { senseListUrl, recommendAreasUrl } = apiMap;

function adaptSenseList(data) {
  const { code, areaInfo, list } = data;
  let name, level;
  let len = data.areaInfo.length;
  let areaName = data.areaInfo.reduce((result, currItem, index) => {
    if (currItem.code === code) {
      name = currItem.name;
      level = currItem.level;
    }
    if (len > 1 && currItem.name) {
      return `${result}${currItem.name}`;
    } else {
      return result;
    }
  }, "");

  if (areaName) {
    list.unshift({ name: areaName });
  }

  return { code, name, level, areaInfo, list };
}

// TOREVIEW, 为啥直接引入解构不行，赋值解构可以
// import { locationUrl, recommendAreasUrl } from "../api";

// 获取到用户当前所在城市
// 根据城市 id 获取印象
// 获取推荐城市列表

const Home = () => {
  const ctx = useContext(SenseContext);
  const { city, citycode } = ctx.state.location;
  console.log(city);
  console.log(citycode);

  const [senseList, setSenseList] = useState([]);
  const [recommendAreas, setRecommendAreas] = useState([]);
  const [refreshFlag, setRefreshFlag] = useState(true);
  const [refreshPosFlag, setRefreshPosFlag] = useState(0);

  function handleRefresh() {
    setRefreshFlag(refreshFlag + 1);
  }

  function handleRefreshPosition() {
    setRefreshPosFlag(refreshPosFlag + 1);
  }

  useEffect(() => {
    if (refreshPosFlag) {
      (async () => {
        const location = await getLocation(true);
        ctx.dispatch(setLocation(location));
      })();
    }
  }, [refreshPosFlag]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await request.get(senseListUrl, {
        params: { code: citycode },
      });
      let resData = result.data;
      if (resData.code === 0) {
        setSenseList(adaptSenseList(resData.data).list);
      } else {
        console.log(resData);
      }
    };
    if (citycode) {
      fetchData();
    }
  }, [citycode]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await request.get(recommendAreasUrl, {
          params: {
            code: citycode,
          },
        });
        console.log("recommendAreas result.data:", result.data.data);
        let resData = result.data;
        if (resData.code === 0) {
          setRecommendAreas(resData.data.list);
        } else {
          console.log(resData);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (citycode) {
      fetchData(citycode);
    }
  }, [citycode, refreshFlag]);

  return (
    <div>
      <WhiteSpace size="lg"></WhiteSpace>
      <SenseSection
        title={`${city || ""}印象：`}
        city={city}
        isHome={true}
        handleRefreshPosition={handleRefreshPosition}
        list={senseList}
        emptyTip="暂无印象"
      ></SenseSection>
      <WhiteSpace size="sm"></WhiteSpace>
      <RecommendSection
        list={recommendAreas}
        title={`其他推荐：`}
        onRefresh={handleRefresh}
      ></RecommendSection>
    </div>
  );
};

export default Home;

const _apiMap = {
  location: "/api/location",
  recommendAreas: "/api/sense/recommend-areas",
  // ua: "/api/tool/ua/analyze",
  // cnid: "/api/tool/cnid/analyze",
  senseList: "/api/sense/list",
  senseTagList: "/api/sense/tag-list",
  senseVote: "/api/sense/vote",
  senseAddTag: "/api/sense/add-tag",
  userInfo: "/api/user/read",
  userReg: "/api/user/reg",
  userLogin: "/api/user/login",
  userLogout: "/api/user/logout",
};

let apiMap = {};
Object.keys(_apiMap).forEach((key) => {
  let currUrl = `${_apiMap[key]}`;
  apiMap[`${key}Url`] = currUrl;
});

// console.log(apiMap);

export default apiMap;

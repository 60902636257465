import axios from "axios";
// import { getCookie } from "./app";

function getCookie(key) {
  let cookieSeries = document.cookie.split(";");
  let obj = {};
  cookieSeries.forEach((currItem) => {
    let currPair = currItem.trim().split("=");
    obj[currPair[0]] = currPair[1];
  });
  if (key) {
    return obj[key];
  } else {
    return obj;
  }
}

const service = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL, // node 环境的不同，对应不同的baseURL
  timeout: 5000, // 请求的超时时间
  //设置默认请求头，使post请求发送的是formdata格式数据// axios的header默认的Content-Type好像是'application/json;charset=UTF-8',我的项目都是用json格式传输，如果需要更改的话，可以用这种方式修改
  headers: {
    "x-csrf-token": getCookie("csrfToken"),
  },
  withCredentials: true, // 允许携带cookie
});

// 发送请求前处理request的数据
axios.defaults.transformRequest = [
  function (data) {
    let newData = "";
    for (let k in data) {
      newData +=
        encodeURIComponent(k) + "=" + encodeURIComponent(data[k]) + "&";
    }
    return newData;
  },
];

// request拦截器
service.interceptors.request.use(
  (config) => {
    // 发送请求之前，要做的业务
    return config;
  },
  (error) => {
    // 错误处理代码

    return Promise.reject(error);
  }
);

// response拦截器
service.interceptors.response.use(
  (response) => {
    // 数据响应之后，要做的业务
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default service;

//   import fetch from '@/utils/fetch'
//   fetch({
//     method: 'get',
//     url: '/users/list'
//   })
//     .then(res => {
//     cosole.log(res)
//   })

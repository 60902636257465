import React from "react";
import { Tag, Card, Switch } from "antd-mobile";
import iconPosition from "../images/icon_position.png";
import iconHometown from "../images/icon_hometown.png";
import { Link } from "react-router-dom";

const SenseList = (props) => {
  function handleClick(currItem, city) {
    return function (e) {
      const urlPrefix = "https://so.toutiao.com/search?keyword=";

      if (currItem.id) {
        const keyword = encodeURIComponent(`${city} ${currItem.name}`);
        window.open(`${urlPrefix}${keyword}`);
      } else {
        window.open(`${urlPrefix}${encodeURIComponent(currItem.name)}`);
      }
    };
  }

  return (
    <Card>
      {props.title ? (
        <Card.Header
          onClick={
            props.isHome
              ? props.handleRefreshPosition
              : (e) => {
                  console.log(e);
                }
          }
          title={props.title}
          thumb={
            props.isHome ? (
              <img
                src={iconPosition}
                alt="重新定位"
                className="custom-icon-lg"
              />
            ) : (
              ""
            )
          }
          extra={
            props.hasUserModel ? (
              <div style={{ paddingRight: "16px" }}>
                <span style={{ paddingRight: "8px", fontSize: "14px" }}>
                  我的
                </span>
                <Switch
                  checked={props.userModel}
                  onChange={() => {
                    props.setUserModel(!props.userModel);
                  }}
                />
              </div>
            ) : props.isHome ? (
              <Link to="/activity/home">
                <img
                  src={iconHometown}
                  title="家乡印象"
                  className="custom-icon-lg"
                ></img>
              </Link>
            ) : (
              ""
            )
          }
        ></Card.Header>
      ) : (
        ""
      )}
      <Card.Body>
        {props.list.length > 0 ? (
          <div className="tag-container">
            {props.list.map((item, index) => (
              <Tag
                key={`sense-${item.code || index}`}
                onChange={handleClick(item, props.city)}
              >
                {item.name}
              </Tag>
            ))}
          </div>
        ) : (
          <p>{props.emptyTip}</p>
        )}
        {props.children}
      </Card.Body>
    </Card>
  );
};

export default SenseList;

import React, { useState, useContext } from "react";
import { SenseContext } from "../store/Sense";
import request from "../utils/request";
import apiMap from "../api";
import {
  WingBlank,
  WhiteSpace,
  InputItem,
  List,
  Button,
  Toast,
} from "antd-mobile";
import iconPlus from "../images/icon_plus.png";
import iconMinus from "../images/icon_minus.png";

import { useLocation, useHistory } from "react-router-dom";

const { senseAddTagUrl } = apiMap;

const AddTag = (props) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [code] = useState(params.get("code") || "110100");
  const [name] = useState(params.get("name") || "北京");
  const [tags, setTags] = useState([""]);
  const history = useHistory();

  const ctx = useContext(SenseContext);
  const { userInfo } = ctx.state;

  function getValidTags() {
    return tags.filter((tag) => tag.trim());
  }

  function setCurrTag(value, index) {
    const currTags = [...tags];
    currTags.splice(index, 1, value);
    setTags(currTags);
  }

  function addTagInput() {
    const currTags = [...tags];
    currTags.push("");
    setTags(currTags);
  }

  function removeCurrTag(index) {
    const currTags = [...tags];
    currTags.splice(index, 1);
    console.log("currTags:", currTags);
    setTags(currTags);
  }

  function showSubmitErr(msg = "") {
    Toast.fail(`提交出现错误${msg ? "，" + msg : ""}`);
  }

  function submitTags() {
    const fetchData = async () => {
      try {
        const result = await request.post(senseAddTagUrl, {
          uuid: userInfo.name,
          code,
          tags: JSON.stringify(getValidTags()),
        });
        console.log("location result.data:", result.data.data);
        let resData = result.data;
        if (resData.code === 0) {
          // const { success, fail } = resData.data;
          Toast.success("添加印象成功");
          console.log(resData.data);
          // setCity(resData.data.city);
          // setCitycode(resData.data.citycode);
        } else {
          console.log(resData.data);
          showSubmitErr();
        }
      } catch (err) {
        // setData(result.data);
        showSubmitErr();
      }
    };
    fetchData();
  }

  function handleBackClick() {
    history.goBack(`/detail/${code}`);
  }

  return (
    <div>
      <WhiteSpace size="lg"></WhiteSpace>
      {/* <AddTagSection title={`添加${city}印象词：`}></AddTagSection> */}
      {/* <WingBlank size="md"> */}
      <List
        renderHeader={() => (
          <h2 className="sub-title">{`添加${name}印象词：`}</h2>
        )}
      >
        {tags.length > 0
          ? tags.map((tag, index) => {
              return (
                <InputItem
                  type="text"
                  placeholder="请输入"
                  onChange={(e) => {
                    console.log("onChange:", e);
                    setCurrTag(e, index);
                  }}
                  value={tag}
                  key={`tag-${index}`}
                  extra={
                    tags.length > 1 ? (
                      <img
                        src={iconMinus}
                        alt="删除"
                        className="custom-icon"
                        onClick={(e) => removeCurrTag(index)}
                      />
                    ) : (
                      ""
                    )
                  }
                ></InputItem>
              );
            })
          : ""}
      </List>
      <WhiteSpace size="sm"></WhiteSpace>
      <WingBlank size="middle">
        <Button inline size="small" onClick={addTagInput}>
          <img
            src={iconPlus}
            className="custom-icon"
            style={{
              marginTop: "-4px",
            }}
            alt="添加"
          />
        </Button>
        {tags.length > 1 ? (
          <Button
            style={{ marginLeft: "12px" }}
            inline
            size="small"
            onClick={(e) => removeCurrTag(tags.length - 1)}
          >
            <img
              src={iconMinus}
              alt="删除"
              className="custom-icon"
              style={{
                marginTop: "-4px",
              }}
            />
          </Button>
        ) : (
          ""
        )}
        <WhiteSpace size="lg"></WhiteSpace>
        <Button
          type="primary"
          disabled={getValidTags().length > 0 ? false : true}
          onClick={submitTags}
        >
          提交
        </Button>
        <WhiteSpace size="lg"></WhiteSpace>
        <Button onClick={() => handleBackClick()}>返回印象页</Button>
      </WingBlank>

      <WhiteSpace size="lg"></WhiteSpace>
    </div>
  );
};

export default AddTag;

import React from "react";

const Footer = (props) => {
  return (
    <div
      style={{
        fontSize: "12px",
        textAlign: "center",
        lineHeight: 1.5,
        marginTop: "20px",
      }}
    >
      Copyright © 2012-2022 <a href="https://readaq.com">readaq.com</a>
      <br />
      备案号：
      <a href="https://beian.miit.gov.cn" target="_blank">
        鄂ICP备15002161号
      </a>
    </div>
  );
};

export default Footer;

import React, { useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { SenseContext } from "../store/Sense";
import { getUuid, getLocation, getUserInfo } from "../utils/app";
import { setUuid, setLocation, setTitle, setUserInfo } from "../action/app";

import logo from "../images/logo_120.png";

const Header = () => {
  const ctx = useContext(SenseContext);
  const { title } = ctx.state;
  const location = useLocation();
  let currUserName = "";

  useEffect(() => {
    (async () => {
      const uuid = await getUuid();
      ctx.dispatch(setUuid(uuid));
      const location = await getLocation();
      ctx.dispatch(setLocation(location));
      const userInfo = await getUserInfo();
      if (userInfo.code === 0) {
        currUserName = userInfo.data.displayName || userInfo.data.name;
        ctx.dispatch(setUserInfo({ ...userInfo.data, isLogin: true }));
      } else {
        ctx.dispatch(setUserInfo({ isLogin: false }));
      }
    })();
  }, []);

  useEffect(() => {
    switch (location.pathname) {
      case "/reg":
        ctx.dispatch(setTitle("用户注册"));
        break;
      case "/login":
        ctx.dispatch(setTitle("用户登录"));
        break;
      case "/activity/home":
        ctx.dispatch(setTitle("家乡印象"));
        break;
      default:
        if (currUserName) {
          ctx.dispatch(setTitle(`欢迎 ${currUserName} 回来`));
        } else {
          ctx.dispatch(setTitle(""));
        }

        break;
    }
  }, [location.pathname, currUserName]);

  return (
    <header className="app-header">
      <Link to="/">
        <img src={logo} className="app-logo" alt="返回首页" title="返回首页" />
      </Link>
      {title ? (
        <h3
          style={{
            fontSize: "24px",
            fontWeight: "normal",
            marginBottom: 0,
            color: "#666",
          }}
        >
          {title}
        </h3>
      ) : (
        ""
      )}
    </header>
  );
};

export default Header;

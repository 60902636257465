import React from "react";
import { Link } from "react-router-dom";
import { Tag, Card } from "antd-mobile";
import iconRefresh from "../images/icon_refresh.png";

const SenseOperation = (props) => {
  return (
    <Card>
      {props.title ? (
        <Card.Header
          title={props.title}
          extra={
            <span onClick={props.onRefresh}>
              <img src={iconRefresh} alt="刷新" className="custom-icon-lg" />
            </span>
          }
        ></Card.Header>
      ) : (
        ""
      )}
      <Card.Body>
        <div className="recommend-areas-container">
          <div className="tag-container">
            {props.list.map((item) => (
              <Tag key={`link-${item.code}`}>
                <Link to={`/detail/${item.code}`}>{item.name}</Link>
              </Tag>
            ))}
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default SenseOperation;

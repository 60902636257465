import React, { useEffect, useState, useContext } from "react";
import request from "../utils/request";
import apiMap from "../api";
import { useHistory } from "react-router-dom";
import { SenseContext } from "../store/Sense";
import {
  Card,
  List,
  Checkbox,
  Button,
  WhiteSpace,
  Toast,
  Switch,
  Flex,
} from "antd-mobile";
import iconRefresh from "../images/icon_refresh.png";
import clone from "lodash.clone";

const CheckboxItem = Checkbox.CheckboxItem;

const { senseVoteUrl } = apiMap;

const SenseOperation = (props) => {
  const ctx = useContext(SenseContext);
  const { userInfo, uuid } = ctx.state;

  const [checkedTags, setCheckedTags] = useState([]);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [extraOptionsInclude, setExtraOptionsInclude] = useState(false);

  const history = useHistory();

  const currUrl = encodeURIComponent(window.location.href);
  const [options, setOptions] = useState(clone(props.options));

  function onItemChange(value, status) {
    setSubmitStatus(false);
    let currChecked = [...checkedTags];
    if (status) {
      currChecked.push(value);
    } else {
      currChecked = currChecked.filter((item) => {
        return item.id !== value.id;
      });
    }
    setCheckedTags(currChecked);
  }

  function modifyOptions(flag) {
    if (flag == "add") {
      let selectedMap = clone(props.extraOptions);
      let candidateMap = clone(props.options);
      const candidateIds = candidateMap.map((currItem) => currItem.id);
      let validSelectedMap = selectedMap.filter((item) => {
        return item.id && candidateIds.indexOf(item.id) === -1;
      });

      setOptions(clone(validSelectedMap.concat(candidateMap)));
    } else {
      setOptions(clone(props.options));
    }
  }

  function handleCreateClick() {
    if (props.loginStatus) {
      history.push(
        `/addtag/?redirectUrl=${currUrl}&code=${props.cityCode}&name=${props.cityName}`
      );
    } else {
      props.handleLogin();
    }
  }

  useEffect(() => {
    if (extraOptionsInclude) {
      modifyOptions("add");
    } else {
      modifyOptions();
    }
  }, [extraOptionsInclude, props.options, props.extraOptions]);

  useEffect(() => {
    if (submitStatus) {
      const fetchData = async () => {
        let userName = "";
        if (props.loginStatus) {
          userName = userInfo.name;
        } else {
          userName = uuid;
        }
        const result = await request.post(senseVoteUrl, {
          code: props.cityCode,
          uuid: userName,
          tags: JSON.stringify(checkedTags),
        });
        console.log("senseVoteUrl result.data:", result.data.data);
        let resData = result.data;
        if (resData.code === 0) {
          Toast.success("你的印象提交成功，稍后生效", 1);
          props.notify && props.notify();
        } else if (resData.code === 403) {
          history.push(`/login?redirectUrl=${currUrl}`);
        } else {
          Toast.info("出现错误，请稍后重试", 1);
          console.log(resData.data);
        }
      };
      fetchData();
    }
  }, [submitStatus]);

  return (
    <div>
      <WhiteSpace size="lg" />
      <Card>
        <Card.Header
          title="印象候选"
          extra={
            <Flex>
              <div style={{ paddingRight: "16px" }}>
                <span style={{ paddingRight: "8px", fontSize: "14px" }}>
                  包含已选项
                </span>
                <Switch
                  checked={extraOptionsInclude}
                  onChange={() => {
                    setExtraOptionsInclude(!extraOptionsInclude);
                  }}
                />
              </div>
              <span onClick={props.onRefresh}>
                <img src={iconRefresh} alt="刷新" className="custom-icon-lg" />
              </span>
            </Flex>
          }
        />
        <Card.Body>
          <List>
            {options.map((item) => (
              <CheckboxItem
                key={item.id}
                onChange={(e) => onItemChange(item, e.target.checked)}
              >
                {item.name}
              </CheckboxItem>
            ))}
          </List>
          <WhiteSpace size="lg" />
          <WhiteSpace size="lg" />
          <Button
            type="primary"
            disabled={checkedTags.length > 0 && !submitStatus ? false : true}
            onClick={(e) => setSubmitStatus(true)}
          >
            提交所选
          </Button>
          <WhiteSpace size="lg" />
          <Button type="ghost" onClick={() => handleCreateClick()}>
            {props.loginStatus
              ? `创建更多${props.cityName}印象`
              : `登录创建更多${props.cityName}印象`}
          </Button>
          <WhiteSpace size="lg" />
        </Card.Body>
      </Card>
    </div>
  );
};

export default SenseOperation;

import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import request from "../utils/request";
import apiMap from "../api";
import SenseSection from "../components/SenseList";
import SenseOperation from "../components/SenseOperation";
import RecommendSubAreas from "../components/RecommendSubAreas";
import { SenseContext } from "../store/Sense";
import { WingBlank, WhiteSpace, Button } from "antd-mobile";

console.log("RecommendSubAreas:", RecommendSubAreas);

const { senseListUrl, senseTagListUrl, recommendAreasUrl } = apiMap;

function adaptSenseList(data) {
  const { code, areaInfo, list } = data;
  console.log(areaInfo);
  let name, level, parentCode, parentName;
  let len = data.areaInfo.length;
  let areaName = data.areaInfo.reduce((result, currItem, index) => {
    if (currItem.code === code) {
      name = currItem.name;
      level = currItem.level;
    }
    if (len > 1 && currItem.name) {
      if (index === len - 2) {
        parentCode = currItem.code;
        parentName = currItem.name;
      }
      return `${result}${currItem.name}`;
    } else {
      return result;
    }
  }, "");

  if (areaName) {
    list.unshift({ name: areaName });
  }

  return { code, name, parentCode, parentName, level, areaInfo, list };
}

const Detail = function () {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  let name = decodeURIComponent(query.get("name"));

  const { id } = useParams();
  const ctx = useContext(SenseContext);
  const { userInfo } = ctx.state;
  console.log("detail userInfo:", userInfo);
  const history = useHistory();
  const currUrl = encodeURIComponent(window.location.href);

  const [senseList, setSenseList] = useState([]);
  const [cityName, setCityName] = useState("");
  const [cityCode, setCityCode] = useState(id);
  const [options, setOptions] = useState([]);
  const [tagRefreshFlag, setTagRefreshFlag] = useState(true);
  const [areaRefreshFlag, setAreaRefreshFlag] = useState(true);
  const [recommendAreas, setRecommendAreas] = useState([]);
  const [needUpdate, setNeedUpdate] = useState(false);
  const [parentName, setParentName] = useState("");
  const [parentCode, setParentCode] = useState("");
  const [userModel, setUserModel] = useState(false);

  function handleTagRefresh() {
    setTagRefreshFlag(!tagRefreshFlag);
  }

  function handleAreaRefresh() {
    setAreaRefreshFlag(!areaRefreshFlag);
  }
  function handleLogin() {
    history.replace(`/login?redirectUrl=${currUrl}`);
  }
  function updateNotidy() {
    setNeedUpdate(!needUpdate);
  }

  function handleOnBack() {
    setCityCode(parentCode);
    setCityName(parentName);
  }

  useEffect(() => {
    setCityCode(id);
    if (name && name !== "null") {
      setCityName(name);
    }
  }, [id]);

  useEffect(() => {
    history.replace(`/detail/${cityCode}?name=${cityName}`);
  }, [cityCode, cityName]);

  // 查询印象集
  useEffect(() => {
    const fetchData = async () => {
      let params = { code: cityCode, name: cityName };
      if (userInfo.isLogin && userModel) {
        params.uuid = userInfo.name;
      }
      const result = await request.get(senseListUrl, {
        params,
      });
      let resData = result.data;
      if (resData.code === 0) {
        const { list, name, code, parentName, parentCode } = adaptSenseList(
          resData.data
        );
        setParentName(parentName);
        setParentCode(parentCode);
        setSenseList(list);
        if (name) {
          setCityName(name);
        }
        setCityCode(code);
      } else {
        console.log(resData);
      }
    };
    fetchData();
  }, [cityCode, needUpdate, userModel]);

  // 查询子级地址
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await request.get(recommendAreasUrl, {
          params: {
            code: cityCode,
            forceNext: true,
          },
        });
        console.log("recommendAreas result.data:", result.data.data);
        let resData = result.data;
        if (resData.code === 0) {
          setRecommendAreas(resData.data.list);
        } else {
          console.log(resData);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [cityCode, areaRefreshFlag]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await request.get(senseTagListUrl, {
        params: { code: cityCode, count: 10 },
      });
      let resData = result.data;
      const { list } = resData.data;
      if (resData.code === 0) {
        setOptions(list);
      } else {
        console.log(resData);
      }
    };
    fetchData();
  }, [cityCode, tagRefreshFlag]);

  return (
    <div>
      <SenseSection
        title={`${cityName}印象`}
        city={cityName}
        list={senseList}
        hasUserModel={userInfo.isLogin ? true : false}
        userModel={userModel}
        setUserModel={setUserModel}
        emptyTip="暂无印象，在下方添加一些吧～"
      >
        <RecommendSubAreas
          list={recommendAreas}
          onRefresh={handleAreaRefresh}
          onBack={handleOnBack}
          hasParent={parentCode ? true : false}
        ></RecommendSubAreas>
      </SenseSection>
      <SenseOperation
        cityCode={cityCode}
        cityName={cityName}
        options={options}
        extraOptions={senseList}
        onRefresh={handleTagRefresh}
        loginStatus={userInfo.isLogin}
        handleLogin={handleLogin}
        notify={updateNotidy}
      ></SenseOperation>
    </div>
  );
};

export default Detail;

import React, { useState, useEffect, useContext } from "react";
import { SenseContext } from "../store/Sense";
import axios from "axios";
import apiMap from "../api";
import { WhiteSpace, Card } from "antd-mobile";

const { userRegUrl } = apiMap;

const Reg = () => {
  return (
    <Card>
      <WhiteSpace size="lg"></WhiteSpace>
      <div
        className="reg-tip"
        style={{
          lineHeight: 1.4,
          fontSize: "14px",
          margin: 0,
          padding: "0 12px",
        }}
      >
        <p>当前为公测阶段</p>
        <p>微信公众号搜索「地理酷」关注并留言【获取账号】</p>
      </div>
    </Card>
  );
};

export default Reg;

import React from "react";
import { Link } from "react-router-dom";
import { List, Badge } from "antd-mobile";
import iconRefresh from "../images/icon_refresh.png";
import iconUp from "../images/icon_up.png";

const RecommendSubAreas = (props) => {
  return (
    <div>
      {props.list.length > 0 ? (
        <List>
          <List.Item wrap={true}>
            {props.hasParent ? (
              <span onClick={props.onBack}>
                <img
                  src={iconUp}
                  alt="上一级"
                  className="custom-icon"
                  style={{
                    width: 20,
                    height: 20,
                    marginRight: 10,
                  }}
                />
              </span>
            ) : (
              ""
            )}
            <span onClick={props.onRefresh}>
              <img
                src={iconRefresh}
                alt="刷新"
                className="custom-icon"
                style={{
                  width: 20,
                  height: 20,
                  marginRight: 0,
                }}
              />
            </span>
            {props.list.map((item) => (
              <Link
                key={`link-${item.code}`}
                to={`/detail/${item.code}?name=${item.name}`}
              >
                <Badge
                  text={item.name}
                  style={{
                    marginLeft: 12,
                    padding: "0 3px",
                    backgroundColor: "#21b68a",
                    borderRadius: 2,
                  }}
                />
              </Link>
            ))}
          </List.Item>
        </List>
      ) : (
        ""
      )}
    </div>
  );
};

export default RecommendSubAreas;

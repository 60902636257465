import "antd-mobile/dist/antd-mobile.css";
import "./index.css";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import SenseStore from "./store/Sense";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/home.js";
import Detail from "./pages/detail.js";
import AddTag from "./pages/addtag.js";
import Reg from "./pages/reg.js";
import Login from "./pages/login.js";
import ActivityHome from "./pages/activities/hometown.js";

const App = () => {
  return (
    <SenseStore>
      <Router>
        <Header></Header>
        <Switch>
          <Route path="/activity/home">
            <ActivityHome />
          </Route>
          <Route path="/reg">
            <Reg />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/detail/:id">
            <Detail />
          </Route>
          <Route path="/addtag">
            <AddTag />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
      <Footer></Footer>
    </SenseStore>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));

import React, { useReducer } from "react";
import appReducer from "../reducer/app.js";

// 创建并 export 上下文
export const SenseContext = React.createContext(null);

function RootComponent(props) {
  //第二个参数为 state 的初始状态
  const [appState, appDispatch] = useReducer(appReducer, {
    location: {},
    uuid: "",
    userInfo: { isLogin: false }, // 用户信息
  });

  return (
    <SenseContext.Provider
      value={{
        state: appState,
        dispatch: appDispatch,
      }}
    >
      {props.children}
    </SenseContext.Provider>
  );
}

export default RootComponent;

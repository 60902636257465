import { SET_LOCATION, SET_UUID, SET_TITLE, SET_USERINFO } from "../action/app";

const Reducer = (state, action) => {
  console.log(action);
  switch (action.type) {
    case SET_LOCATION:
      return Object.assign({}, state, {
        location: action.location,
      });
    case SET_UUID:
      return Object.assign({}, state, {
        uuid: action.uuid,
      });
    case SET_TITLE:
      return Object.assign({}, state, {
        title: action.title,
      });
    case SET_USERINFO:
      return Object.assign({}, state, {
        userInfo: action.userInfo,
      });
    default:
      return state;
  }
};

export default Reducer;

import FingerprintJS from "@fingerprintjs/fingerprintjs";
import request from "./request";
import apiMap from "../api";

const { locationUrl, userInfoUrl } = apiMap;
const uuidStorageKey = "sense_uuid";
const locationStorageKey = "sense_location";

export const getUuid = () => {
  let uuid = localStorage.getItem(uuidStorageKey) || "";
  async function initFingerprint() {
    const fp = await FingerprintJS.load();

    const result = await fp.get({
      canvas: true,
      audio: true,
    });
    uuid = result.visitorId;
    localStorage.setItem(uuidStorageKey, uuid);
    return uuid;
  }
  if (!uuid) {
    return initFingerprint();
  }
  return Promise.resolve(uuid);
};

export const getLocation = (force = false) => {
  const defaultData = { city: "北京", citycode: "110100" };
  async function fetchLocation() {
    try {
      const result = await request(locationUrl);
      let resData = result.data;
      if (resData.code === 0) {
        localStorage.setItem(
          locationStorageKey,
          JSON.stringify({
            ...resData.data,
            timeStamp: Date.now(),
          })
        );
        const { city, citycode } = resData.data;
        return { city, citycode };
      } else {
        return defaultData;
      }
    } catch (error) {
      return defaultData;
    }
  }
  try {
    let location = JSON.parse(localStorage.getItem(locationStorageKey));
    // localstorage 中不存在，或者已过期（有效期：7天）
    if (
      !location ||
      (location && location.timeStamp < Date.now() - 1000 * 60 * 60 * 24 * 7) ||
      force
    ) {
      return fetchLocation();
    } else {
      const { city, citycode } = location;
      return Promise.resolve({ city, citycode });
    }
  } catch (err) {
    return Promise.resolve(defaultData);
  }
};

export const getUserInfo = () => {
  async function fetchUserInfo() {
    try {
      const result = await request(userInfoUrl);
      return result.data;
    } catch (error) {
      return {};
    }
  }
  return fetchUserInfo();
};

export const getCookie = (key) => {
  let cookieSeries = document.cookie.split(";");
  let obj = {};
  cookieSeries.forEach((currItem) => {
    let currPair = currItem.trim().split("=");
    obj[currPair[0]] = currPair[1];
  });
  if (key) {
    return obj[key];
  } else {
    return obj;
  }
};

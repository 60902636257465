import React, { useState, useEffect, useContext } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";

import { SenseContext } from "../store/Sense";
import request from "../utils/request";
import apiMap from "../api";
import {
  WingBlank,
  WhiteSpace,
  List,
  InputItem,
  Button,
  Toast,
  Flex,
} from "antd-mobile";

const { userLoginUrl } = apiMap;

const Login = () => {
  const ctx = useContext(SenseContext);
  // console.log(decodeURIComponent(location.search));

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [usernameErr, setUsernameErr] = useState(false);
  const [passwordErr, setPasswordErr] = useState(false);

  const history = useHistory();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const redirectUrl = decodeURIComponent(query.get("redirectUrl"));

  console.log(redirectUrl);

  function handleLogin() {
    const fetchData = async () => {
      try {
        const result = await request.post(userLoginUrl, {
          username,
          password,
        });
        console.log("location result.data:", result.data.data);
        let resData = result.data;
        if (resData.code === 0) {
          Toast.success("登录成功");
          window.location.replace(redirectUrl);
        } else {
          console.log(resData.data);
          showSubmitErr();
        }
      } catch (err) {
        showSubmitErr();
      }
    };
    fetchData();
  }

  function showSubmitErr(msg = "") {
    Toast.fail(`登录出现错误，${msg ? "，" + msg : ""}`);
  }

  function handleUsernameErr(e) {
    console.log("UsernameErr:", e);
  }
  function handleUsernameBlur(e) {
    console.log("handleUsernameBlur:", e);
  }
  function handleUsernameChange(e) {
    setUsername(e);
    console.log("handleUsernameChange:", e);
  }

  function handlePasswordErr(e) {
    console.log("handlePasswordErr:", e);
  }
  function handlePasswordBlur(e) {
    console.log("handlePasswordBlur:", e);
  }
  function handlePasswordChange(e) {
    setPassword(e);
    console.log("handlePasswordChange:", e);
  }

  return (
    <div>
      <WhiteSpace size="lg"></WhiteSpace>
      <WingBlank>
        <List>
          <InputItem
            placeholder="手机号/邮箱"
            name="username"
            value={username}
            error={usernameErr}
            onErrorClick={handleUsernameErr}
            onChange={handleUsernameChange}
            onBlur={handleUsernameBlur}
          ></InputItem>
          <InputItem
            placeholder="密码"
            name="password"
            type="password"
            value={password}
            error={passwordErr}
            onErrorClick={handlePasswordErr}
            onChange={handlePasswordChange}
            onBlur={handlePasswordBlur}
          ></InputItem>
        </List>
        <WhiteSpace size="md"></WhiteSpace>
        <Button
          type="primary"
          disabled={usernameErr || passwordErr}
          onClick={handleLogin}
        >
          登录
        </Button>
        <WhiteSpace size="lg"></WhiteSpace>

        <Flex justify="start">
          <Link to="/reg" style={{ fontSize: "16px", color: "#999" }}>
            立即注册
          </Link>
        </Flex>
      </WingBlank>
    </div>
  );
};

export default Login;

import React, { useState, useContext } from "react";
import { SenseContext } from "../../store/Sense";

import {
  WingBlank,
  WhiteSpace,
  InputItem,
  List,
  Button,
  Toast,
} from "antd-mobile";

import { useHistory } from "react-router-dom";

const ActivityHome = (props) => {
  const [code, setCode] = useState("");
  const history = useHistory();

  const ctx = useContext(SenseContext);
  const { userInfo } = ctx.state;

  function validCode(e) {
    let innerCode = code;
    if (e) {
      innerCode = e;
    }
    innerCode = innerCode.trim();
    return innerCode && /^\d{6}$/.test(innerCode);
  }

  function handleClick() {
    history.push(`/detail/${code}`);
  }

  return (
    <div>
      <WhiteSpace size="lg"></WhiteSpace>
      <List>
        <InputItem
          type="text"
          placeholder="输入身份证前 6 位"
          onChange={setCode}
          style={{ textAlign: "center" }}
          value={code}
          key={`code`}
        ></InputItem>
      </List>
      <WhiteSpace size="lg"></WhiteSpace>
      <WingBlank size="middle">
        <Button
          type="primary"
          disabled={validCode() ? false : true}
          onClick={handleClick}
        >
          查看家乡印象
        </Button>
        <WhiteSpace size="lg"></WhiteSpace>
      </WingBlank>
    </div>
  );
};

export default ActivityHome;

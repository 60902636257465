const SET_LOCATION = "SET_LOCATION";
const SET_UUID = "SET_UUID";
const SET_TITLE = "SET_TITLE";
const SET_USERINFO = "SET_USERINFO";

// 保存位置信息
const setLocation = (location) => ({
  type: SET_LOCATION,
  location: location,
  receivedAt: Date.now(),
});

// 保存 uuid
const setUuid = (uuid) => ({
  type: SET_UUID,
  uuid: uuid,
  receivedAt: Date.now(),
});

// 保存 title
const setTitle = (title) => ({
  type: SET_TITLE,
  title: title,
  receivedAt: Date.now(),
});

// 保存用户信息
const setUserInfo = (userInfo) => ({
  type: SET_USERINFO,
  userInfo: userInfo,
  receivedAt: Date.now(),
});

export {
  SET_LOCATION,
  SET_UUID,
  SET_TITLE,
  SET_USERINFO,
  setLocation,
  setUuid,
  setTitle,
  setUserInfo,
};
